@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap');

@layer base {
  html {
    font-family: 'Poppins', sans-serif;
  }
}

.hide-down-arrow {
  display: none;
}